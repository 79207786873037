import React from 'react'
import Hbox from '../../ui/UiHbox'
import { Typography } from '@material-ui/core'
import { RealOrGratuito } from '../../utils'
import Pluralize from 'react-pluralize'
import styled from 'styled-components'
import { UiLink } from '../../ui'

const UiLinkStyled = styled(UiLink)`
  && {
    margin-left: 5px;
  }
`

const PluralizeStyled = styled(Pluralize)`
  && {
    font-weight: 500;
  }
`

const Total = styled(Typography)`
  && {
    font-weight: 500;
  }
`

function ResumeFragment({ qty = 0, total = 0, drawer, handleDrawer }) {
  qty = qty || 0
  total = total || 0
  return (
    <Hbox>
      <Typography variant="subtitle1">
        <PluralizeStyled singular="item" plural="itens" count={qty} />
        <UiLinkStyled href="#" onClick={handleDrawer}>
          {drawer ? 'Ocultar detalhes' : 'Ver detalhes'}
        </UiLinkStyled>
      </Typography>
      <Total variant="button" display="block" align="right">
        <RealOrGratuito value={total} />
      </Total>
    </Hbox>
  )
}

export default ResumeFragment

import * as React from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

interface ITextProps {
  fontSize?: string
  marginBottom?: string
  marginTop?: string
  color?: string
}

const UiText = styled(({ fontSize, marginBottom, marginTop, color, ...props }) => (
  <Typography {...props} />
))`
  && {
    color: ${({ color }: ITextProps) => color || 'rgba(0, 0, 0, 0.90)'};
    font-weight: 500;
    font-size: ${({ fontSize }: ITextProps) => fontSize || '16px'};
    margin-bottom: ${({ marginBottom }: ITextProps) => marginBottom || '0px'};
    margin-top: ${({ marginTop }: ITextProps) => marginTop || '0px'};
  }
`
export default UiText

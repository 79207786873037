import React from 'react'
import CardForm from './CardForm'
import useStore from '../../stores/useStore'
import { view } from 'react-easy-state'

function BoletoContainer() {
  const { debitInput: input } = useStore()

  return <CardForm input={input} />
}

export default view(BoletoContainer)

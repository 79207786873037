import wretch from 'wretch'
import { getToken } from './business/authentication'
import Tracker from './plugins/tracker'
import { API_URL } from './env'
let token = getToken()

// const delayMiddleware = delay => next => (url, opts) => {
//   return new Promise(res => setTimeout(() => res(next(url, opts)), delay))
// }

const addToken = w => (token ? w.auth(`Bearer ${token}`) : w)

const convertToJson = w => w.json()
const captureToken = promise =>
  promise.then(data => {
    if (data && data.meta && data.meta.token) {
      token = data.meta.token
      Tracker.signIn(data.id)
    }
    return data
  })

const R = wretch(API_URL)
  .url('/api/v2')
  // .middlewares([delayMiddleware(2000)])
  .catcher('SyntaxError', e => {
    console.error(e)
    throw Error('Erro inesperado')
  }) // bad json
  .catcher(404, e => {
    console.error(e)
    throw Error('Serviço não encontrado.')
  })
  .catcher(403, e => {
    console.error(e)
    throw Error('Serviço não autorizado. Fale com o administrador da sua rede.')
  })
  .catcher(500, e => {
    console.error(e)
    throw Error('Serviço indisponível.')
  })
  .errorType('json')
  .accept('application/json')
  .content('application/json')
  .defer(addToken)
  .resolve(convertToJson)
  .resolve(captureToken)

export default R

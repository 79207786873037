import useLoading from './useLoading'

export default function useBusy(fn, uid) {
  const [loading, setLoading] = useLoading(uid)

  function off(...args) {
    setLoading(false)
    return args
  }

  function busyFn(...args) {
    setLoading(true)
    const promise = fn(...args)
    if (promise) {
      promise.then(off)
      promise.catch(off)
    }
    return promise
  }

  return [loading, busyFn, setLoading]
}

import React, { useEffect } from 'react'
import { DoneLayout } from '../DoneLayout'
import { DonePaidScreen } from './DonePaidScreen'
import { Pixel } from '../../../business'
import useStore from 'stores/useStore'

export function DonePage() {
  const { event, leaderParticipant } = useStore()

  useEffect(() => {
    const sendPixel = async () => {
      Pixel.Purchase(event.integrations.pixel_id, {
        currency: 'BRL',
        value: leaderParticipant.amount,
      })
    }
    event.integrations?.pixel_purchase && sendPixel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event.integrations?.pixel_id, event.integrations?.pixel_purchase, leaderParticipant?.amount])

  return (
    <DoneLayout>
      <DonePaidScreen />
    </DoneLayout>
  )
}

import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga'

const Tracker = {
  integrations: {},

  init(integrations) {
    this.integrations = integrations || {}

    if (this.integrations.pixel) {
      ReactPixel.init(this.integrations.pixel, {}, { debug: false })
    }

    if (this.integrations.ga) {
      ReactGA.initialize(this.integrations.ga, { debug: false })
    }
  },

  signIn(userId) {
    if (this.integrations.ga) {
      ReactGA.set({ userId })
    }
  },

  pageView(path = window.location.search) {
    if (this.integrations.pixel) {
      // https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
      ReactPixel.pageView()
    }

    if (this.integrations.ga) {
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  },

  event(action) {
    if (this.integrations.ga) {
      ReactGA.event({
        category: 'inscricao',
        action,
      })
    }
  },
}

export default Tracker

import React, { useCallback } from 'react'
import { fileListToArray } from '../utils'
import { pipe } from '../utils'

const eventToFileList = e => e.target.files
const multipleOrSingle = multiple => ary => (multiple ? ary : ary[0])

function wrap(onChange, multiple) {
  return pipe(eventToFileList, fileListToArray, multipleOrSingle(multiple), onChange)
}

const style = {
  display: 'block',
  position: 'absolute',
  cursor: 'pointer',
  width: '100%',
  height: '100%',
  zIndex: 2,
  opacity: 0,
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  margin: 0,
  padding: 0,
}

export default function UiFileInput({ onChange, multiple, ...props }) {
  const callback = useCallback(wrap(onChange), [onChange])
  return <input type="file" style={style} onChange={callback} multiple={multiple} {...props} />
}

import styled from 'styled-components'
import { UiSelect } from '../../ui'

interface ITextProps {
  fontSize?: string
  marginBottom?: string
  marginTop?: string
}

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const ColOne = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

export const Select = styled(UiSelect)`
  && {
    margin-top: 0px;
  }
`

import React from 'react'
import useStore from '../../stores/useStore'
import PixForm from './PixForm'
import { view } from 'react-easy-state'

function PixFormContainer() {
  const { pixInput } = useStore()

  return <PixForm input={pixInput} />
}

export default view(PixFormContainer)

// https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands
import React from 'react'
import { UiButtonText } from '../../../ui'
import { Link } from 'wouter'
import { SigninForm } from './SignInForm'
import { SignupLink } from '../shared/SignupLink'
import { UiVbox } from '../../../ui'

export function SigninPage() {
  return (
    <UiVbox>
      <SigninForm />
      <ForgetPasswordButton />
      <SignupLink />
    </UiVbox>
  )
}

function ForgetPasswordButton(props: any) {
  return (
    <Link href="/auth/forgot-password">
      <UiButtonText>Esqueci minha senha</UiButtonText>
    </Link>
  )
}

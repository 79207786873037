import React from 'react'
import { UiButtonText } from '../../../ui'
import { Link } from 'wouter'

export function SigninLink() {
  return (
    <Link href="/auth/signin">
      <UiButtonText>Entrar numa conta existente</UiButtonText>
    </Link>
  )
}

import React from 'react'
import BackButton from '../../components/BackButton'
import AppBarLayout from './AppBarLayout'
import StepperContainer from './StepperContainer'
import back from '../../utils/back'
import useStore from '../../stores/useStore'
import { useLocation } from 'wouter'
import { view } from 'react-easy-state'

function AppBarContainer() {
  const { title } = useStore()
  const [location] = useLocation()
  const disabledBack = location === '/tickets' || location === '/'
  const hasBackButton =
    location === '/tickets' ||
    location === '/' ||
    location === '/done/pix' ||
    location === '/done/pixResult' ||
    location === '/done/ErrorPage' ||
    location === '/done/ProcessingPage'

  return (
    <AppBarLayout
      title={title}
      backButton={!hasBackButton && <BackButton onClick={back} disabled={disabledBack} />}
      stepper={<StepperContainer />}
    />
  )
}

export default view(AppBarContainer)

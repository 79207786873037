import React from 'react'
import { Toolbar } from '@material-ui/core'

const styles = {
  ToolBar: { minHeight: 50 }
}

export default ({ children }) => (
  <Toolbar style={styles.ToolBar} disableGutters>
    {children}
  </Toolbar>
)

import React from 'react'
import { Card, CardContent, Link } from '@material-ui/core'
import { UiText } from '../../ui'

export default function PixDocumentLayout({ children }: any) {
  return (
    <Card>
      <CardContent>
        <UiText fontSize="12px" marginBottom="6px">
          Para pagamento via PIX, precisamos do CPF/CNPJ do pagador para registro no comprovante de
          pagamento.
        </UiText>
        <Link href="https://home.e-inscricao.com/" target="_blank">
          <UiText fontSize="10px" color="#0da2ff">
            Saiba como usamos seus dados, acessando nossa LGPD.
          </UiText>
        </Link>
        {children}
      </CardContent>
    </Card>
  )
}

import React, { useEffect } from 'react'
import { DoneLayout } from '../DoneLayout'
import { DoneBoletoScreen } from './DoneBoletoScreen'
import useStore from '../../../stores/useStore'
import { Pixel } from '../../../business'

export function DonePage(props: any) {
  const {
    pagarmeBoleto: {
      transaction: {
        boleto_barcode,
        boleto_url,
        customer: { email },
      },
    },
    leaderParticipant,
    event,
  } = useStore()

  useEffect(() => {
    const sendPixel = async () => {
      Pixel.Purchase(event.integrations.pixel_id, {
        currency: 'BRL',
        value: leaderParticipant.amount,
      })
    }
    event.integrations?.pixel_purchase && sendPixel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event.integrations?.pixel_id, event.integrations?.pixel_purchase, leaderParticipant.amount])

  return (
    <DoneLayout>
      <DoneBoletoScreen
        barcode={boleto_barcode}
        url={boleto_url}
        email={email}
        leaderParticipant={leaderParticipant}
      />
    </DoneLayout>
  )
}

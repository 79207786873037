import React from 'react'
import { Typography } from '@material-ui/core'
import { useToggle } from 'react-use'

type InstalmentProps = {
  instalmentOptions: (string | number)[][]
}

export function InstalmentsDetails({ instalmentOptions }: InstalmentProps) {
  const [open, toggle] = useToggle(false)
  const message = open ? 'ocultar parcelas' : 'ver parcelas'

  return (
    <div style={{ pointerEvents: 'auto' }}>
      {open && <Instalments instalmentOptions={instalmentOptions} />}
      <Typography
        component="span"
        color="secondary"
        variant="body2"
        onClick={toggle}
        style={{ cursor: 'pointer' }}>
        {message}
      </Typography>
    </div>
  )
}

function Instalments({ instalmentOptions }: InstalmentProps) {
  return (
    <p style={{ color: '#262121' }}>
      {instalmentOptions.map(([i, label]) => (
        <span style={{ display: 'block' }} key={i}>
          {label}
        </span>
      ))}
    </p>
  )
}

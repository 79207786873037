export default function NumericInputDecorator(input) {
  return function({ value, onChange = console.warn, ...props }) {
    value = int(value)
    return input({
      value,
      onChange: v => onChange(int(v)),
      ...props
    })
  }
}

function int(s) {
  return String(s).replace(/\D/g, '')
}

import React, { useContext } from 'react'
// import { fields } from 'test/fixtures/event'

const CustomFieldsContext = React.createContext()

function useCustomFieldsContext() {
  return useContext(CustomFieldsContext) || []
}

export { CustomFieldsContext, useCustomFieldsContext }

import React, { ReactNode } from 'react'
import { AppBar, Box } from '@material-ui/core'
import { ReactComponent as Logo } from '../../assets/img/logo.svg'
import { CheckCircleOutlineIcon } from '../../icons'

type Props = {
  children: ReactNode
}

export function DoneLayout({ children }: Props) {
  return (
    <>
      <AppBar style={{ height: 50 }} elevation={0} position="relative">
        <Logo width="40%" style={{ margin: 'auto' }} />
      </AppBar>
      <Box textAlign="center" mt={1} p={1}>
        <CheckCircleOutlineIcon style={{ fontSize: 90 }} color="secondary" />
        {children}
      </Box>
    </>
  )
}

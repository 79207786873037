import { DocumentTypeContext } from '../../inputs/DocumentTypeInput'
import { CustomFieldsContext } from './CustomFieldsModule/CustomFieldsContext'
import React from 'react'
import { view } from 'react-easy-state'
import useStore from '../../stores/useStore'
import ParticipantItem from './ParticipantItem'

export default view(ParticipantsContainer)

function ParticipantsContainer() {
  const { fields, documents, participantsInput } = useStore()

  return (
    <>
      <CustomFieldsContext.Provider value={fields}>
        <DocumentTypeContext.Provider value={documents}>
          {participantsInput.map((props: any) => (
            <ParticipantItem key={props.id} {...props} />
          ))}
        </DocumentTypeContext.Provider>
      </CustomFieldsContext.Provider>
    </>
  )
}

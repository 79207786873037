import React, { useState, useEffect } from 'react'
import { Typography, IconButton, Icon, Card, Collapse } from '@material-ui/core'
import styled from 'styled-components'

// @TODO Usar esse depois do merge da branch 'storybook'
// import UiCard from '../UiCard'

import { ReactComponent as Mad } from './icons/hate.svg'
import { ReactComponent as Sad } from './icons/deslike.svg'
import { ReactComponent as Confused } from './icons/neutral.svg'
import { ReactComponent as Happy } from './icons/like.svg'
import { ReactComponent as Love } from './icons/love.svg'

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

const FeedbackQuestion = styled(Typography)`
  && {
    text-align: center;
    font-weight: 500;
  }
`

const UiCard = styled(Card)`
  && {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-top: 18px;
  }
`

const NewIconButton = ({ children }) => (
  <IconButton>
    <Icon>{children}</Icon>
  </IconButton>
)

const QUESTMSG = 'Como você avalia sua experiência durante a inscrição?'
const FINALMSG = 'Obrigado por compartilhar sua experiência conosco!'

const UiFeedback = ({ feedbackMsg = null, doneMsg = null, onClick, ...props }) => {
  const [questionMessage, setQuestionMessage] = useState(feedbackMsg || QUESTMSG)
  const [hideIcons, setHideIcons] = useState(true)

  useEffect(() => {
    setQuestionMessage(feedbackMsg || QUESTMSG)
  }, [feedbackMsg])

  const handleVoteClick = note => {
    onClick(note)
    setQuestionMessage(doneMsg || FINALMSG)
    setHideIcons(false)
  }

  return (
    <UiCard {...props}>
      <FeedbackQuestion>{questionMessage}</FeedbackQuestion>
      <Collapse in={hideIcons}>
        <IconContainer>
          <NewIconButton>
            <Mad onClick={() => handleVoteClick(1)} />
          </NewIconButton>
          <NewIconButton>
            <Sad onClick={() => handleVoteClick(2)} />
          </NewIconButton>
          <NewIconButton>
            <Confused onClick={() => handleVoteClick(3)} />
          </NewIconButton>
          <NewIconButton>
            <Happy onClick={() => handleVoteClick(4)} />
          </NewIconButton>
          <NewIconButton>
            <Love onClick={() => handleVoteClick(5)} />
          </NewIconButton>
        </IconContainer>
      </Collapse>
    </UiCard>
  )
}

export default UiFeedback

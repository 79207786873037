import React from 'react'
import { UiHbox } from '../../ui'
import DocumentTypeInput from '../../inputs/DocumentTypeInput'
import DocumentInput from '../../inputs/DocumentInput'
import { view } from 'react-easy-state'

function PixForm(props: any) {
  const { input = console.warn } = props
  const { value: documentType } = input('document_type') || {}
  return (
    <form>
      <UiHbox itemProps={undefined}>
        <DocumentTypeInput types={['cpf', 'cnpj']} {...input('document_type')} />
        <DocumentInput required documentType={documentType} {...input('document_value')} />
      </UiHbox>
    </form>
  )
}

export default view(PixForm)

import React from 'react'
import { view } from 'react-easy-state'
import useStore from '../../stores/useStore'
import { Box, FormControlLabel, Typography, Switch } from '@material-ui/core'

function SameParticipantToggle() {
  const { canShowSameParticipantToggle, isSameParticipant, toggleSameParticipant } = useStore()

  return canShowSameParticipantToggle ? (
    <Box m={1} mx={2}>
      <FormControlLabel
        label={<Typography variant="body2">Preencher iguais todas as inscrições</Typography>}
        control={<Switch checked={isSameParticipant} onClick={toggleSameParticipant} />}
      />
    </Box>
  ) : null
}

export default view(SameParticipantToggle)

export default function calcGroupDiscount(
  groupDiscount,
  selectedCategories,
  leaderDiscount,
  qty = false
) {
  if (!groupDiscount) return null
  let qtys = selectedCategories.map(o => o.qty).reduce(sum, 0)
  if (qtys < groupDiscount.min_group_size) return null
  let total = calcTotal(selectedCategories)
  total -= leaderDiscount
  if (leaderDiscount) qtys -= 1
  if (!qty) {
    return (
      (total * groupDiscount.percentage_value) / 100 + groupDiscount.amount_value_in_cents * qtys ||
      null
    )
  }
  return {
    discount:
      (total * groupDiscount.percentage_value) / 100 + groupDiscount.amount_value_in_cents * qtys ||
      null,
    qty: qtys,
  }
}

function sum(a, b) {
  return Number(a) + Number(b)
}

function calcTotal(selectedCategories) {
  return selectedCategories.map(o => o.price * o.qty).reduce(sum, 0)
}

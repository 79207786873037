export const defaultPaymentMethods = [
  {
    id: 'credit_card',
    payment_type: 'cartao',
    payment_method: 'credit_card',
    amount_in_cents: 0,
    number_of_instalments: 1,
    begin_at: null,
    end_at: null,
    boleto_due_at: null,
  },
  {
    id: 'pix',
    payment_type: 'pix',
    payment_method: 'pix',
    amount_in_cents: 0,
    number_of_instalments: 1,
    begin_at: null,
    end_at: null,
    boleto_due_at: null,
  },
]

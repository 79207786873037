import React from 'react'
import {
  FormControlLabel,
  Checkbox,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  FormHelperText,
} from '@material-ui/core'
import targetChecked from '../../../../utils/targetChecked'
import useToggle from 'react-use/lib/useToggle'

export default ({ value = false, term, onChange, error, label, ...props }) => {
  const [open, toggle] = useToggle(false)

  function accept() {
    onChange(true)
    toggle(false)
  }

  function notAccept() {
    onChange(false)
    toggle(false)
  }

  return (
    <FormControl style={{ marginTop: 10 }} error={!!error}>
      <FormControlLabel
        {...props}
        checked={value}
        control={<Checkbox onChange={targetChecked(onChange)} />}
        label={
          <div>
            <Link component="button" onClick={toggle} variant="body1" color="textSecondary">
              {`Confirmo que li e aceito: ${label || ''}`}
            </Link>
            {error && <FormHelperText margin="dense">{error}</FormHelperText>}
          </div>
        }
      />
      <Dialog open={open} scroll="paper">
        <DialogTitle>{label || ''}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ whiteSpace: 'pre-wrap' }}>{term}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={notAccept} color="background">
            não aceito
          </Button>
          <Button onClick={accept} color="secondary">
            aceito
          </Button>
        </DialogActions>
      </Dialog>
    </FormControl>
  )
}

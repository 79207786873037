import React, { useEffect } from 'react'
import { UiVbox, UiFeedbackSubscription } from '../../../ui'
import { Typography } from '@material-ui/core'
import SaveComprovanteButton from './SaveComprovanteButton'
import CardQRCode from '../../../components/CardQRCode'
import MyTicketsButtonStateful from '../shared/MyTicketsButtonStateful'
import styled from 'styled-components'
import useStore from '../../../stores/useStore'
import receiptLink from '../../../business/receipt/receiptLink'
import receiptFilename from '../../../business/receipt/receiptFilename'
import GTM from '../../../business/gtm/googleTagManager'
import useRedirect from '../../../hooks/useRedirect'

const SaveComprovanteButtonStyled = styled(SaveComprovanteButton)`
  && {
    text-transform: none;
    border: 2px solid;
    :hover {
      border: 2px solid;
    }
  }
`

export function DonePaidScreen(props: any) {
  const {
    participantsInput,
    getParticipantOptionals,
    setIsSubscriptionComplete,
    selectedCategories,
    sumWithFee,
    enabledCoupons,
    leaderParticipant,
    event,
  } = useStore()

  //Adicionar aqui os eventos e links para redirecionamento após finalização de compra.
  //https://app.clickup.com/t/86a53uayw
  //TODO Adicionar esse object como variável de ambiente do cloudflare pra não precisar modificar hardcoded.
  const redirects = [{ id: 77608, url: 'https://cnj2024.com.br/ingresso-reservado/' }]

  participantsInput.forEach((participant: any) => {
    getParticipantOptionals?.forEach((optional: any) => {
      if (participant.id === optional.participantId) participant.optionals = optional.optionals
    })
  })

  const gaItems = selectedCategories.map((el: any) => {
    return {
      ...el,
      item_id: el.id,
      item_name: el.name,
      item_category: el.name,
      price: Number(el.price / 100),
    }
  })

  const gaValueBrl = sumWithFee / 100

  useEffect(() => {
    setIsSubscriptionComplete(true)
    GTM.Purchase(gaItems, gaValueBrl, enabledCoupons.join(', '), leaderParticipant.id)
  }, [gaItems, setIsSubscriptionComplete, gaValueBrl, enabledCoupons, leaderParticipant])

  useRedirect(event, redirects)

  return (
    <UiVbox spacing={0}>
      <Typography variant="h6">Pedido feito com sucesso!</Typography>
      <Typography variant="subtitle1">
        Os detalhes do seu pedido e comprovante de <br />
        inscrição foram enviados para o seu email.
      </Typography>
      {participantsInput &&
        participantsInput.map((participant: any) => {
          const url = receiptLink(participant.id)
          const filename = receiptFilename(participant.id)
          const optionals = participant.optionals || []
          return (
            <CardQRCode key={participant.id} participant={participant} optionals={optionals}>
              <SaveComprovanteButtonStyled variant="outlined" url={url} filename={filename} />
            </CardQRCode>
          )
        })}

      <MyTicketsButtonStateful />
      <UiFeedbackSubscription />
    </UiVbox>
  )
}

import React from 'react'
import { Typography } from '@material-ui/core'

export default ({ children, ...props }) => (
  <div style={{ marginTop: 20 }} {...props}>
    <Typography variant="subtitle2" gutterBottom>
      Dados Complementares
    </Typography>
    {children}
  </div>
)

function copyToClipboard(string) {
  const textField = document.createElement('textarea')
  textField.innerText = string
  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove()
}

export default copyToClipboard

import React from 'react'
import AppContainer from './AppContainer'
import Pages from './pages'
// import Lab from './lab'

export default function App() {
  return (
    <AppContainer>
      <Pages />
      {/* <Lab /> */}
    </AppContainer>
  )
}

import React from 'react'
import Stepper from './Stepper'
import { useLocation } from 'wouter'
import useStore from '../../../stores/useStore'

export default function StepperContainer() {
  let [location] = useLocation()
  location = location.split('/')[1] // trim slash
  const { stepsLabels, stepsIndexAtLocation } = useStore()

  const activeStep = stepsIndexAtLocation(location)
  return <Stepper activeStep={activeStep} steps={stepsLabels} />
}

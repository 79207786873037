import React from 'react'
import get from 'lodash/get'
import { connect } from 'formik'

import UiTextField from '../../ui/UiTextField'

const FieldFormik = ({ name, formik, type, placeholder, ...props }) => {
  const handleChange = value => {
    formik.setFieldValue(name, value)
  }

  const { values, errors, touched, handleBlur } = formik
  const value = get(values, name, '')
  const wasTouched = get(touched, name)
  const fieldError = wasTouched && get(errors, name, null)

  return (
    <UiTextField
      error={fieldError}
      helperText={fieldError}
      value={value}
      onChange={value => handleChange(value)}
      onBlur={handleBlur}
      name={name}
      type={type || 'text'}
      placeholder={placeholder}
      {...props}
    />
  )
}

export default connect(FieldFormik)

import React from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'

const ButtonStyled = styled(Button)`
  && {
    border-radius: 20px;
    font-weight: bold;
  }
`

const CircularProgressStyled = styled(CircularProgress)`
  && {
    position: absolute;
  }
`

const CustomButton = ({ children, loading = false, ...props }) => {
  return (
    <ButtonStyled disabled={loading} {...props}>
      {children}
      {loading && <CircularProgressStyled size={24} />}
    </ButtonStyled>
  )
}

export default CustomButton

import React from 'react'
import { UiTextField } from '../ui'
import NumericInputDecorator from './NumericInputDecorator'

export default NumericInputDecorator(function CvvInput(props) {
  return (
    <UiTextField
      label="cvv"
      inputProps={{ maxLength: 4, inputMode: 'numeric' }}
      required
      {...props}
    />
  )
})

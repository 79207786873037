import { createStore } from 'reusable'
import { useState } from 'react'

const useLoadingStore = createStore(args => useState({}))

export default function useLoading(uid = 'loading') {
  const [state, setState] = useLoadingStore()
  const loading = !!state[uid]
  function setLoading(bool) {
    setState(prevState => {
      return { ...prevState, [uid]: bool }
    })
  }
  return [loading, setLoading]
}

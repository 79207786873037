import React from 'react'
import { Box, Typography } from '@material-ui/core'

type GroupDiscountProps = {
  messages: string[]
}

export function GroupDiscount({ messages }: GroupDiscountProps) {
  return (
    <Box p={1} pb={0}>
      <Typography variant="body1" align="center" color="primary" style={{ fontWeight: 500 }}>
        {messages[0]}
      </Typography>
      {messages[1] && (
        <Typography variant="body2" align="center">
          {messages[1]}
        </Typography>
      )}
      {messages[2] && (
        <Typography variant="body2" align="center">
          {messages[2]}
        </Typography>
      )}
    </Box>
  )
}

import React from 'react'
import { UiSelect } from '../../../../ui'

const MARITAL_STATUS = [
  ['not_informed', 'Não Informado'],
  ['single', 'Solteiro(a)'],
  ['married', 'Casado(a)'],
  ['separated', 'Separado(a)'],
  ['divorced', 'Divorciado(a)'],
  ['widower', 'Viúvo(a)'],
]

export default props => <UiSelect options={MARITAL_STATUS} {...props} />

import React from 'react'
import { MuiThemeProviderLight } from './theme'
import { CssBaseline } from '@material-ui/core'
import { SnackbarQueue } from './components'
import { ReusableProvider } from 'reusable'

export default ({ children }) => (
  <MuiThemeProviderLight>
    <CssBaseline />
    <SnackbarQueue closeable autoHideDuration={3000} />
    <ReusableProvider>{children}</ReusableProvider>
  </MuiThemeProviderLight>
)

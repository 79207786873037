import React, { useState, useEffect } from 'react'
import FooterBarLayout from './FooterBarLayout'
import ResumeContainer from './ResumeContainer'
import DetailsCell from './DetailsCell'
import useStore from '../../stores/useStore'
import { view } from 'react-easy-state'

function FooterBarContainer(props) {
  const { hasItems } = useStore()
  const [drawer, setDrawer] = useState(false)

  const handleDrawer = () => setDrawer(!!!drawer)

  // aplica paddingBottom no #root para o conteúdo não ficar escondido atrás do Footer
  useEffect(() => {
    const el = document.getElementById('root')
    if (!el) return
    const old = el.style.paddingBottom
    el.style.paddingBottom = `${103}px`
    return () => (el.style.paddingBottom = old)
  }, [])

  return (
    <FooterBarLayout
      handleDrawer={handleDrawer}
      resume={hasItems && <ResumeContainer handleDrawer={handleDrawer} drawer={drawer} />}
      details={hasItems && <DetailsCell />}
      drawer={drawer}
      {...props}
    />
  )
}

export default view(FooterBarContainer)

import React from 'react'
import { IconButton } from '@material-ui/core'
import PropTypes from 'prop-types'
import { ArrowBackIosIcon } from '../icons'

const style = {
  marginLeft: 8,
  padding: 0,
  position: 'absolute',
}

const BackButton = props => (
  <IconButton
    disableRipple
    {...props}
    // TODO colocar o estilo aqui ou no componente que for utilizá-lo?
    style={style}>
    <ArrowBackIosIcon />
  </IconButton>
)

BackButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default BackButton

import React from 'react'
import { UiTextField, UiHbox } from '../../ui'
import DocumentTypeInput from '../../inputs/DocumentTypeInput'
import InstalmentInput from '../../inputs/InstalmentInput'
import DocumentInput from '../../inputs/DocumentInput'
import { view } from 'react-easy-state'

function BoletoForm(props) {
  const { instalments = [], input = console.warn } = props
  const { value: documentType } = input('document_type') || {}
  return (
    <form>
      <NameInput {...input('name')} />

      <UiHbox>
        <DocumentTypeInput types={['cpf', 'cnpj']} {...input('document_type')} />

        <DocumentInput required documentType={documentType} {...input('document_value')} />
      </UiHbox>

      <InstalmentInput options={instalments} {...input('instalments')} />
    </form>
  )
}

function NameInput(props) {
  return <UiTextField label="Nome completo" required {...props} />
}

export default view(BoletoForm)

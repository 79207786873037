import React from 'react'
import HeaderBarLayout from './HeaderBarLayout'
import ToolbarLayout from './ToolbarLayout'
import { Typography } from '@material-ui/core'

const styles = {
  Typography: { flexGrow: 1, marginLeft: 24, marginRight: 24 }
}

const Title = props => (
  <Typography
    style={styles.Typography}
    variant="subtitle1"
    align="center"
    color="inherit"
    noWrap
    {...props}
  />
)

export default ({ backButton, title, stepper, ...props }) => (
  <HeaderBarLayout {...props}>
    <ToolbarLayout>
      {backButton}
      <Title>{title}</Title>
    </ToolbarLayout>

    {stepper && <ToolbarLayout>{stepper}</ToolbarLayout>}
  </HeaderBarLayout>
)

import React from 'react'
import { Grid } from '@material-ui/core'

export default function Hbox({ children = [], itemProps, ...props }) {
  return (
    <Grid container direction="row" spacing={1} {...props}>
      {React.Children.map(children, (child, i) => (
        <Grid item xs={props.spacing === 0 ? i === 0 : true} {...itemProps}>
          {child}
        </Grid>
      ))}
    </Grid>
  )
}

import fileToJson from './fileToJson'

export default function fileListToArray(fileList) {
  const files = []
  for (let i = 0; i < fileList.length; i++) {
    let file = fileList[i]
    files.push(file)
    // make file compatible with JSON.stringify
    file.toJSON =
      file.toJSON ||
      function() {
        return fileToJson(this)
      }
  }
  return files
}

import React from 'react'
import { Card, CardContent, Typography } from '@material-ui/core'

export default function CardLayout({ children }) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6">Dados do cartão de dédito</Typography>
        {children}
      </CardContent>
    </Card>
  )
}

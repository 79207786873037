import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { view } from 'react-easy-state'
import { UiButton } from '../../ui'
import { SadFace } from '../../icons'
import { useLocation } from 'wouter'
import useStore from '../../stores/useStore'
import AppBarContainer from '../../shared/AppBarContainer'

// import useStore from 'stores/useStore'

function ErrorPage() {
  const [, setLocation] = useLocation()
  const { resultMessage, regenerateCodes } = useStore()

  return (
    <>
      <AppBarContainer />
      <Box textAlign="center" mt={4}>
        <SadFace />
      </Box>
      <Box m={1}>
        <Box m={2}>
          <Typography variant="h6" align="center">
            Ocorreu um problema
          </Typography>
        </Box>
        <Box m={4}>
          <Typography variant="body2" align="center">
            {resultMessage}
          </Typography>
        </Box>
        <Box m={4}>
          <UiButton
            color="secondary"
            onClick={() => {
              setLocation('/tickets')
              regenerateCodes()
            }}>
            Reiniciar Inscrição
          </UiButton>
        </Box>
      </Box>
    </>
  )
}

export default view(ErrorPage)

import React from 'react'
import { Button } from '@material-ui/core'

export default function UiButton(props) {
  return (
    <Button
      size="large"
      color="secondary"
      variant="contained"
      fullWidth
      {...props}
      style={{
        boxShadow: 'none',
        borderRadius: 20,
        fontWeight: 'bold',
        ...props.style
      }}
    />
  )
}

import React from 'react'
import { List } from '@material-ui/core'
import { PaymentListItem } from './PaymentListItem'

type ListItemProps = {
  id: number | string
  payment_type: string
  amount_in_cents: number
  number_of_instalments: number
  begin_at: string
  end_at: string
  price: number
}

type ListProps = {
  list: ListItemProps[]
}

export function PaymentList({ list = [] }: ListProps) {
  return (
    <List component="div" disablePadding>
      {list.map((props: ListItemProps) => (
        <PaymentListItem key={props.id} {...props} />
      ))}
    </List>
  )
}

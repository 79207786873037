/*
 * Função que cria um novo objeto contendo apenas campos que existem
 * no evento, e remove os tipo list e multi_choice.
 */

function formatMultiChoice(multiChoiceField) {
  if (Array.isArray(multiChoiceField)) return multiChoiceField
  const field = []
  field.push(Number(multiChoiceField))
  return field
}

export function mapExistingFields(msFields, eventFields) {
  const fields = {}

  if (eventFields.length === 0) {
    return fields
  }

  for (const field in msFields) {
    const isFieldPresent = eventFields.some(el => String(el.id) === field)
    if (isFieldPresent) {
      fields[field] = msFields[field]
      eventFields.forEach(el => {
        if (String(el.id) === field && el.kind === 'list') {
          const isOptionPresent = el.options.some(el => String(el.id) === String(fields[field]))
          if (!isOptionPresent) delete fields[field]
        }
        if (String(el.id) === field && el.kind === 'multi_choice') {
          fields[field] = formatMultiChoice(fields[field])
          const optionsIds = el.options.map(el => el.id)
          const intersection = fields[field].filter(el => optionsIds.includes(el))
          if (intersection.length === 0) delete fields[field]
        }
      })
    }
  }
  return fields
}

import BackButton from '../../components/BackButton'
import React from 'react'
import { useEvent } from 'react-use'
import AppBarLayout from '../../shared/AppBarContainer/AppBarLayout'
import UiIframe from '../../ui/UiIframe'
import back from '../../utils/back'
import useStore from '../../stores/useStore'
import { noty } from '../../components/SnackbarQueue'
import { useLocation } from 'wouter'

export default function DebitPage() {
  const { cielo, payment_method_id, enrollByDebit } = useStore()
  useEvent('message', cieloReturnCallback)
  const [, setLocation] = useLocation()

  function cieloReturnCallback({ data }) {
    console.info(data.cielo)
    if (data.cielo.Payment.CapturedAmount === data.cielo.Payment.Amount) {
      return enrollByDebit(data.cielo)
        .then(() => setLocation('/done/paid'))
        .catch(e => noty.error(e.message))
    } else {
      noty.error(data.cielo.Payment.ReturnMessage)
      setLocation(`/payment/debit/${payment_method_id}`)
    }
  }

  return (
    <div style={styles.root}>
      <AppBarLayout title="Débito" backButton={<BackButton onClick={back} />} />
      <UiIframe title="débito" src={cielo.Payment.AuthenticationUrl}></UiIframe>
    </div>
  )
}

const styles = {
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
}

import React from 'react'
import CategoryList from './CategoryList'
import useStore from '../../stores/useStore'
import { view } from 'react-easy-state'

function CategoryListContainer() {
  const { categoryList, paymentMethodsWithPrice } = useStore()
  return <CategoryList list={categoryList} paymentMethods={paymentMethodsWithPrice} />
}

export default view(CategoryListContainer)

import React from 'react'
import {
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  FormControl
} from '@material-ui/core'

export default ({ value, onChange, label, ...props }) => (
  <FormControl margin="dense" fullWidth {...props}>
    <FormLabel>{label}</FormLabel>
    <RadioGroup row value={value || ''} onChange={onChange}>
      <FormControlLabel value="Feminino" control={<Radio />} label="Feminino" />
      <FormControlLabel
        value="Masculino"
        control={<Radio />}
        label="Masculino"
      />
    </RadioGroup>
  </FormControl>
)

import React from 'react'
import { CircularProgress } from '@material-ui/core'

const style = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  height: '100%',
  width: '100%'
}

export default ({ progress, ...props }) => (
  <CircularProgress
    value={progress}
    variant={progress > 0 ? 'determinate' : 'indeterminate'}
    style={style}
    {...props}
  />
)

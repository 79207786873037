export const Pixel = {
  AddToCart(pixelId, data) {
    InitPixel(pixelId, 'AddToCart', data)
  },
  Purchase(pixelId, data) {
    InitPixel(pixelId, 'Purchase', data)
  },
  Lead(pixelId, data) {
    InitPixel(pixelId, 'Lead', data)
  },
  AddPaymentInfo(pixelId, data) {
    InitPixel(pixelId, 'AddPaymentInfo', data)
  },
  InitiateCheckout(pixelId, data) {
    InitPixel(pixelId, 'InitiateCheckout', data)
  },
}

function InitPixel(pixelId, event, data) {
  /* eslint-disable */
  if (typeof fbq === 'undefined') {
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
    fbq('init', pixelId)
    if (data) {
      fbq('track', event)
    } else {
      fbq('track', event, data)
    }
  } else {
    if (data) {
      fbq('track', event, data)
    } else {
      fbq('track', event)
    }
  }
}

import { noty } from '../../components/SnackbarQueue'
import React from 'react'
import API from '../../store/api'
import { UiButton, UiEmailInput, UiVbox } from '../../ui'
import UiBusyWrapper from '../../ui/UiBusyWrapper'
import { SigninLink } from './shared/SigninLink'
import { SignupLink } from './shared/SignupLink'
import { useEmailInputStore } from './shared/useEmailInputStore'

export function ForgotPasswordPage(props: any) {
  const [emailInput, emailValid] = useEmailInputStore()
  return (
    <UiVbox spacing={3}>
      <UiEmailInput {...emailInput} />
      <UiBusyWrapper uid="forgot_password" onClick={() => sendRememberEmail(emailInput.value)}>
        <UiButton disabled={!emailValid}>Enviar Email</UiButton>
      </UiBusyWrapper>
      <React.Fragment />
      <SigninLink />
      <SignupLink />
    </UiVbox>
  )
}

function sendRememberEmail(email: string) {
  // return wait(5)
  return API.resetPassword(email).then(() => noty.success('Instruções enviadas por email'))
}

import React, { useState } from 'react'
import ChipsInput from '../../inputs/ChipsInput'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import useStore from '../../stores/useStore'
import { view } from 'react-easy-state'

export default view(function () {
  const [value, setValue] = useState('')
  const [error, setError] = useState('')
  const { avaliableCoupons, enabledCoupons, removeCoupon, addCupon } = useStore()

  function onAdd() {
    if (enabledCoupons.includes(value)) {
      setError('Cupom Já Ativado!')
      return
    }

    if (avaliableCoupons.includes(value)) {
      enabledCoupons.push(value)
      addCupon(value)
      setValue('')
    } else {
      setError('Cupom Inválido!')
    }
  }

  function updateValue(value: string) {
    setValue(value)
    setError('')
  }

  return (
    <Paper>
      <Box m={1} p={1}>
        <ChipsInput
          error={error}
          onChange={updateValue}
          onAdd={onAdd}
          value={value}
          onDelete={removeCoupon}
          chipsList={enabledCoupons}
          placeholder="Digite seu cupom de desconto"
        />
      </Box>
    </Paper>
  )
})

import CountryKind from './CountryKind'
import GenderKind from './GenderKind'
import ListKind from './ListKind'
import MaritalStatusKind from './MaritalStatusKind'
import MultiChoiceCheckKind from './MultiChoiceCheckKind'
import StateKind from './StateKind'
import TermKind from './TermKind'
import TextKind from './TextKind'
import ZipKind from './ZipKind'
import CellPhoneKind from './CellPhoneKind'
import BirthDateKind from './BirthDateKind'
import FileKind from './FileKind'

const MAP = {
  country: CountryKind,
  gender: GenderKind,
  list: ListKind,
  marital_status: MaritalStatusKind,
  multi_choice: MultiChoiceCheckKind,
  state: StateKind,
  term: TermKind,
  text: TextKind,
  zipcode: ZipKind,
  cell_phone: CellPhoneKind,
  birthdate: BirthDateKind,
  file: FileKind,
}

export function componentForKind(kind) {
  return MAP[kind] || TextKind
}

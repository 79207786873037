import React from 'react'
import UiFireUpload from '../../../../ui/UiFireUpload'
import UiLabel from '../../../../ui/UiLabel'
import { safeFilename } from '../../../../utils'

// converts "2020-06-25T12:27:13.395Z"
// into: "2020-06-25/12-27-13-395"
function suffix() {
  const rx = /^(\d{4})-(\d\d)-(\d\d)T(\d\d):(\d\d):(\d\d)\.(\d+)Z/
  const replacer = '$1-$2-$3/$4-$5-$6-$7'
  return new Date().toJSON().replace(rx, replacer)
}

function pathBuilder(file) {
  return suffix() + '~' + file.size + '~' + safeFilename(file.name)
}

export default ({ required, value, onChange, label }) => {
  return (
    <UiLabel required={required} fontSize="14px" marginTop="10px">
      <UiFireUpload pathBuilder={pathBuilder} value={value} onChange={onChange} />
      {label}
    </UiLabel>
  )
}

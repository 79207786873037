import React, { useState } from 'react'
import UiFireUploadView from './UiFireUploadView'
import { upload } from '../../plugins/firebase'
import { useMemoCallback } from '../../utils'

function onFileSelected(file, setProgress, onChange, pathBuilder) {
  if (!file) return onChange(null)
  const reset = () => setProgress()
  setProgress(0)
  const path = pathBuilder(file)
  const uploadTask = upload(path, file)
  uploadTask
    .then(uploadTaskSnapshot => uploadTaskSnapshot.ref.getDownloadURL())
    .then(onChange)
    .then(reset)
    .catch(reset)
  uploadTask.on('state_changed', function(snapshot) {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
    setProgress(progress)
  })
}

export default ({ value, onChange, pathBuilder, ...props }) => {
  const [progress, setProgress] = useState()

  const onChangeMemo = useMemoCallback(onFileSelected, setProgress, onChange, pathBuilder)

  return (
    <UiFireUploadView isDone={!!value} progress={progress} onChange={onChangeMemo} {...props} />
  )
}

// https://firebasestorage.googleapis.com/v0/b/jovial-honor-245415.appspot.com/o/2019-08%2F04%2ForgID~org-slug~evenID~event-slug%2Fzzz-zzz-zzz~045458~acao_23_38-26.png?alt=media&token=31aacda3-2afa-4e7a-a744-8c197640b3d8

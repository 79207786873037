import React from 'react'
import { MuiThemeProviderDark } from '../../../theme'
import css from './AuthLayout.module.css'
import { CssBaseline } from '@material-ui/core'
import { ReactComponent as Logo } from '../../../assets/img/logo.svg'
import { UiButton } from '../../../ui'

function goBack() {
  window.history.back()
}

type LayoutProps = {
  children: React.ReactNode
  onBack?: () => void
}

export function AuthLayout({ children, onBack = goBack }: LayoutProps) {
  return (
    <MuiThemeProviderDark>
      <CssBaseline />
      <div className={css.root}>
        <Logo className={css.logo} />
        <div className={css.wrapper}>{children}</div>
        <UiButton onClick={onBack} variant="text" color="default" size="large" fullWidth>
          Voltar
        </UiButton>
      </div>
    </MuiThemeProviderDark>
  )
}

import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { UiButton } from '../../ui'
import { Link } from 'wouter'
import useStore from '../../stores/useStore'

export function LoginPage() {
  const { allowAnonymous } = useStore()
  return (
    <Grid container direction="column" spacing={4} alignItems="stretch">
      <Grid item>
        <Typography variant="body2" align="center">
          Crie sua conta para gerenciar suas <br /> inscrições e facilitar futuros cadastros
        </Typography>
      </Grid>

      <Grid item>
        <SignUpButton />
      </Grid>

      <Grid item>
        <SignInButton />
      </Grid>

      {allowAnonymous && (
        <Grid item>
          <AnonymousButton />
        </Grid>
      )}
    </Grid>
  )
}

function SignUpButton() {
  return (
    <Link href="/auth/signup">
      <UiButton>Criar Conta</UiButton>
    </Link>
  )
}

function SignInButton() {
  return (
    <Link href="/auth/signin">
      <UiButton color="default">Login</UiButton>
    </Link>
  )
}

function AnonymousButton() {
  return (
    <Link href="/participants">
      <UiButton size="large" color="default" variant="outlined">
        Continuar Sem Conta
      </UiButton>
    </Link>
  )
}

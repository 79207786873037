import React, { useEffect } from 'react'
import AppBarContainer from '../../shared/AppBarContainer'
import FooterBarContainer from '../../shared/FooterBarContainer'
import { view } from 'react-easy-state'
import NextButtonContainer from './NextButtonContainer'
import ParticipantsContainer from './ParticipantsContainer'
import useStore from '../../stores/useStore'
import SameParticipantToggle from './SameParticipantToggle'
import FreeButtonContainer from './FreeButtonContainer'

function ParticipantsPage() {
  const { buildParticipants, participants, isFree, hasOptional } = useStore()
  // FIXME: quando o usuário é removido do session, acontece um loop infinito no useEffect
  useEffect(() => {
    if (participants.length === 0) buildParticipants()
  }, [participants, buildParticipants])
  return (
    <>
      <AppBarContainer />
      <SameParticipantToggle />

      <ParticipantsContainer />

      <FooterBarContainer>
        {isFree && !hasOptional ? <FreeButtonContainer /> : <NextButtonContainer />}
      </FooterBarContainer>
    </>
  )
}

export default view(ParticipantsPage)

import { useReducer } from 'react'

function reducer(state, patch) {
  return { ...state, ...patch }
}

function usePatch(initialState = {}) {
  return useReducer(reducer, initialState)
}

export default usePatch

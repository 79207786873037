import { useState } from 'react'

export default function useInput(initialState = '') {
  const [value, onChange] = useState(initialState)

  const helpers = {
    get isEmpty() {
      return this.value.trim().length === 0
    },

    reset() {
      onChange('')
    }
  }

  const object = {
    value: {
      value,
      enumerable: true
    },
    onChange: {
      value: onChange,
      enumerable: true
    }
  }

  return Object.create(helpers, object)
}

import React from 'react'
import { Spinner } from '../ui'
import useLoading from '../hooks/useLoading'

export default function UiBusyWrapper(props) {
  const { children, onClick, busy = false, uid = 'loading' } = props
  let [loading, setLoading] = useLoading(uid)
  loading = loading || busy

  function off() {
    setLoading(false)
  }

  const cloneProps = {}

  if (loading) {
    cloneProps.disabled = true
  }

  if (onClick) {
    cloneProps.onClick = function(e) {
      const promise = onClick(e)
      if (promise) {
        setLoading(true)
        promise.then(off)
        promise.catch(off)
      }
    }
  }

  const Child = React.cloneElement(React.Children.only(children), cloneProps)
  if (!loading) return Child

  return (
    <div style={style.wrapper}>
      <Spinner color="secondary" size={24} style={style.progress} />
      {Child}
    </div>
  )
}

const style = {
  wrapper: { position: 'relative', width: '100%' },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}

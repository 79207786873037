const calculateInterestAmount = (value, parcel, fee) => {
  const totalRound = parseInt(Math.round(value + value * (Number(fee) / 100) * (parcel - 1)))
  // console.log(
  //   '==== return ',
  //   totalRound,
  //   parcel,
  //   parseInt(Math.round(totalRound / parcel)),
  //   parseInt(Math.round(totalRound / parcel)) * parcel
  // )
  return parseInt(Math.round(totalRound / parcel)) * parcel
}

export { calculateInterestAmount }

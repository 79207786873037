import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

export default function UiCheckbox({
  value,
  onChange,
  defaultValue,
  label,
  required,
  disabled,
  ...props
}) {
  // cant have defaultValue and value at same time
  // defaultValue is used by react-text-mask
  value = defaultValue !== undefined ? undefined : value || '' // force to be controlled
  return (
    <>
      <FormControlLabel
        value={value}
        control={
          <Checkbox
            value={value}
            disabled={disabled}
            onChange={e => onChange && onChange(e.target.checked)}
            {...props}
          />
        }
        label={label}
        labelPlacement="end"
      />
    </>
  )
}

export const localStorage = safe('local')
export const sessionStorage = safe('session')

function safe(name) {
  const storage = `${name}Storage`
  try {
    return window[storage]
  } catch {
    console.warn('[SafeStorage]', `Using Mock for ${storage}`)
    return mockStorage()
  }
}

function mockStorage() {
  let storage = {}

  const keys = () => Object.keys(storage)

  return {
    get length() {
      return keys().length
    },

    clear() {
      storage = {}
    },

    getItem(key) {
      return storage[key] ?? null
    },

    setItem(key, value) {
      storage[key] = value
    },

    removeItem(key) {
      delete storage[key]
    },

    key(index) {
      return keys()[index] ?? null
    },

    toString() {
      return '[object Storage]'
    },
  }
}

import CardInput from '../../inputs/CardInput'
import ExpirationInput from '../../inputs/ExpirationInput'
import React from 'react'
import { view } from 'react-easy-state'
import { UiHbox, UiTextField } from '../../ui'

function CardForm({ input = console.warn }) {
  return (
    <form>
      <NameInput {...input('name')} />

      <UiHbox>
        <CardInput {...input('number')} style={{ flexGrow: 1 }} />
        <ExpirationInput {...input('expiration')} />
      </UiHbox>
    </form>
  )
}

function NameInput(props) {
  return <UiTextField label="Nome impresso no cartão" required {...props} />
}

export default view(CardForm)

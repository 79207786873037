import React from 'react'
import { UiTextField } from '../ui'
import NumericInputDecorator from './NumericInputDecorator'

export default NumericInputDecorator(function CardInput(props) {
  return (
    <UiTextField
      label="Número do cartão"
      type="tel"
      inputProps={{ maxLength: 16 }}
      required
      {...props}
    />
  )
})

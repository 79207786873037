import React from 'react'
import { UiButton, Spinner } from '../../ui'

export function SpinnerCentered({ size = 40, style, ...props }) {
  const margin = -size / 2
  return (
    <Spinner
      color="secondary"
      size={size}
      {...props}
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        marginLeft: margin,
        marginTop: margin,
        ...style,
      }}
    />
  )
}

const disableIfBusy = (children, busy) =>
  React.cloneElement(React.Children.only(children), busy ? { disabled: true } : {})

const RelativeInlineBlock = ({ children, style, block, ...props }) => (
  <div
    {...props}
    style={{
      position: 'relative',
      display: block ? 'block' : 'inline-block',
      width: block ? '100%' : 'auto',
      ...style,
    }}>
    {children}
  </div>
)

function Busy({ busy, size = 30, children, SpinProps, block = false, ...props }) {
  return (
    <RelativeInlineBlock block={block} {...props}>
      {busy && <SpinnerCentered size={size} {...SpinProps} />}
      {disableIfBusy(children, busy)}
    </RelativeInlineBlock>
  )
}

function withBusy(Component, BusyProps) {
  return function WithBusy({ busy, ...props }) {
    return (
      <Busy busy={busy} {...BusyProps}>
        <Component {...props} />
      </Busy>
    )
  }
}

export const BusyButton = withBusy(UiButton)

export default Busy

import React from 'react'
import Layout from './Layout'
import CustomFields from './CustomFields'
import { useCustomFieldsContext } from './CustomFieldsContext'

export default ({ data, value, onChange, errors, ...props }) => {
  data = data || useCustomFieldsContext()
  const hasCustomFields = Object.keys(data).length > 0
  return !hasCustomFields
    ? null
    : hasCustomFields && (
        <Layout>
          <CustomFields value={value} onChange={onChange} data={data} errors={errors} />
        </Layout>
      )
}

import React from 'react'
import { AuthLayout } from './AuthLayout'
import { LoginPage } from './LoginPage'
import { SigninPage } from './SigninPage'
import { SignupPage } from './SignupPage'
import { Route, useLocation } from 'wouter'
import { ForgotPasswordPage } from './ForgotPasswordPage'
import { isLoggedIn } from '../../business/authentication'

export function AuthPage() {
  const [, setLocation] = useLocation()
  isLoggedIn() && setLocation('/')
  return (
    <AuthLayout>
      <Route path="/auth" component={LoginPage} />
      <Route path="/auth/signin" component={SigninPage} />
      <Route path="/auth/signup" component={SignupPage} />
      <Route path="/auth/forgot-password" component={ForgotPasswordPage} />
    </AuthLayout>
  )
}

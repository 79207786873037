import React from 'react'
import { UiCpfCnpjInput } from '../ui'
import isDocumentValid from '../utils/isDocumentValid'

export default function DocumentInput({ documentType, value, error, ...props }) {
  error = error || documentError(documentType, value)

  return <UiCpfCnpjInput {...props} {...{ documentType, value, error }} />
}

function documentError(documentType, value) {
  if (documentType === 'cpf') {
    return String(value).length === 14 && !isDocumentValid(value, 'cpf') && 'cpf inválido'
  }
  if (documentType === 'cnpj') {
    return String(value).length === 18 && !isDocumentValid(value, 'cnpj') && 'cnpj inválido'
  }
}

import React from 'react'
import UiTextField from '../ui/UiTextField'
import Chip from '@material-ui/core/Chip'
import styled from 'styled-components'
import Button from '../components/Button'

const ChipContent = styled.div`
  width: 100%;
  margin-top: 5px;
  .MuiChip-sizeSmall {
    margin-right: 10px;
    margin-bottom: 5px;
  }
`

const ButtonStyled = styled(Button)`
  && {
    text-transform: capitalize;
    width: 90px;
    margin-left: 10px;
    height: 41px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
`

const ChipsInput = ({ onChange, value = '', onAdd, onDelete, chipsList = [], ...props }) => {
  const onKeyPress = event => {
    if (event.key === 'Enter') {
      onAdd()
      // const newChips = chips.concat(value)
      // setChips(newChips)
      // chipsList(newChips)
      // onChange('')
    }
  }

  return (
    <>
      <Content>
        <UiTextField
          onKeyPress={onKeyPress}
          onChange={value => onChange(value.toUpperCase())}
          value={value}
          {...props}
        />
        <ButtonStyled
          disabled={value.length === 0}
          size="small"
          color="secondary"
          variant="outlined"
          onClick={() => onKeyPress({ key: 'Enter' })}>
          Aplicar
        </ButtonStyled>
      </Content>
      <ChipContent>
        {chipsList.map(value => (
          <Chip key={value} size="small" label={value} onDelete={() => onDelete(value)} />
        ))}
      </ChipContent>
    </>
  )
}

export default ChipsInput

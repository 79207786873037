import React from 'react'
import { UiSnackbar } from '../ui'
import { usePatch } from '../utils'

const queue = []
var current = {}
var patchCurrent = () => null

function handleExited() {
  processQueue()
}

function handleClose(event, reason) {
  if (reason === 'clickaway') return
  patchCurrent({ open: false })
}

const SnackbarQueue = props => {
  ;[current, patchCurrent] = usePatch()
  return <UiSnackbar {...props} {...current} onClose={handleClose} onExited={handleExited} />
}

function processQueue() {
  if (queue.length > 0) {
    patchCurrent(queue.shift())
  }
}

function noty(message, opt = {}) {
  queue.push({
    message,
    key: new Date().getTime(),
    open: true,
    variant: undefined,
    autoHideDuration: 8000,
    ...opt,
  })
  // set open: true para enfileirar as mensagens, esperando ela fechar sozinha
  current.open ? patchCurrent({ open: false }) : processQueue()
}

noty.success = (msg, opt) => noty(msg, { ...opt, variant: 'success' })
noty.warning = (msg, opt) => noty(msg, { ...opt, variant: 'warning' })
noty.error = (msg, opt) => noty(msg, { ...opt, variant: 'error' })
noty.info = (msg, opt) => noty(msg, { ...opt, variant: 'info' })

export { noty }
export default SnackbarQueue

import React, { useEffect } from 'react'
import AppBarContainer from '../../shared/AppBarContainer'
import FooterBarContainer from '../../shared/FooterBarContainer'
import { UiButton } from '../../ui'
import CardLayout from './CardLayout'
import { Box } from '@material-ui/core'
import CardFormContainer from './CardFormContainer'
import { useLocation } from 'wouter'
import useStore from '../../stores/useStore'
import { view } from 'react-easy-state'
import useBusy from '../../hooks/useBusy'
import UiBusyWrapper from '../../ui/UiBusyWrapper'
import { noty } from '../../components/SnackbarQueue'

function PaymentDebitPage({ params: { id } }) {
  id = Number(id)
  const { setPaymentMethodId, debitInvalid, sendDebit } = useStore()
  const [busy, busyPay] = useBusy(pay, 'PaymentDebitPage')
  const [, setLocation] = useLocation()

  useEffect(() => {
    setPaymentMethodId(id)
  }, [setPaymentMethodId, id])

  function pay() {
    return sendDebit()
      .then(cielo => {
        if (cielo.Payment.AuthenticationUrl) {
          setLocation('/debit')
        } else {
          noty.error(cielo.Payment.ReturnMessage)
        }
      })
      .catch(e => noty.error(e.json?.message?.substr(0, 100)))
  }

  return (
    <>
      <AppBarContainer />

      <Box p={1} mt={1}>
        <CardLayout>
          <CardFormContainer />
        </CardLayout>
      </Box>

      <FooterBarContainer>
        <UiBusyWrapper busy={busy} uid="PaymentDebitPage">
          <UiButton disabled={debitInvalid} onClick={busyPay}>
            pagar e finalizar
          </UiButton>
        </UiBusyWrapper>
      </FooterBarContainer>
    </>
  )
}

export default view(PaymentDebitPage)

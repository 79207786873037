import React from 'react'
import UiMaskedInput from './UiMaskedInput'

export default function UiCpfCnpjInput({ documentType, ...props }) {
  return <UiMaskedInput mask={getMaskForDocumentType(documentType)} label="Número" {...props} />
}

const D2 = [/\d/, /\d/]
const D3 = [/\d/, /\d/, /\d/]
const D4 = [/\d/, /\d/, /\d/, /\d/]

const MASK_CPF = [...D3, '.', ...D3, '.', ...D3, '-', ...D2]
const MASK_CNPJ = [...D2, '.', ...D3, '.', ...D3, '/', ...D4, '-', ...D2]

function getMaskForDocumentType(documentType) {
  if (documentType === 'cpf') return MASK_CPF
  if (documentType === 'cnpj') return MASK_CNPJ
  return false
}

import { MenuItem, ListItemText } from '@material-ui/core'
import React from 'react'
import FieldFormik from './FieldFormik'

const SelectNative = ({ options = [], allowEmpty = false, multiple = false, ...props }) => (
  <FieldFormik select SelectProps={{ native: true, multiple }} {...props}>
    {allowEmpty && <option value={false} />}

    {options.map(([key, label, disabled]) => (
      <option key={key} value={key} disabled={disabled}>
        {label}
      </option>
    ))}
  </FieldFormik>
)

const SelectMenu = ({ options = [], allowEmpty = false, ...props }) => (
  <FieldFormik select {...props}>
    {allowEmpty && <MenuItem value={false} />}

    {options.map(([key, label, disabled]) => (
      <MenuItem key={key} value={key} disabled={disabled}>
        <ListItemText primary={label} />
      </MenuItem>
    ))}
  </FieldFormik>
)

const UiSelect = ({ native = false, multiple = false, ...props }) =>
  native ? <SelectNative multiple={multiple} {...props} /> : <SelectMenu {...props} />

export default UiSelect

import merge from 'deepmerge'
import global from './global'

export default merge(global, {
  // Maneira encontrada para configurar o FluxoStepper
  // https://stackoverflow.com/questions/52394040/material-ui-override-step-icon-style
  overrides: {
    MuiStepIcon: {
      root: {
        '&$active': {
          color: 'white',
          fontWeight: 'bold'
        }
      },
      text: {
        fill: global.palette.primary.main,
        fontWeight: '500'
      }
    }
  },
  palette: {
    type: 'dark',
    background: {
      default: global.palette.primary.main,
      paper: global.palette.primary.main
    }
  }
})

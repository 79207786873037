import R from './request'
import API from './store/api'
import { noty } from './components/SnackbarQueue'
import env from './env'

const $global = { R, API, noty, env }

window.$global = $global

export default $global

window.log = (...data) => console.log(...data.map(d => JSON.stringify(d, 0, 2))) || data

window.logEvent = method => (...args) => console.log(...args) || method

import React from 'react'
import { AppBar, Toolbar } from '@material-ui/core'
import { version } from '../../../../package.json'
import classes from './index.module.css'

export default function FooterBarLayout(props) {
  const { drawer, resume, details, children } = props

  return (
    <>
      {drawer && <Overlay onClick={props.handleDrawer} />}
      <Root>
        {drawer && details}
        {drawer && <hr className={classes.hr} />}
        {resume && <Toolbar className={classes.Toolbar}>{resume}</Toolbar>}
        <Toolbar>{children}</Toolbar>
        <div
          title={`v${version} - ${process.env.REACT_APP_BUILD_DATE}`}
          style={{
            textAlign: 'center',
            fontSize: '9px',
            opacity: '0.3',
            cursor: 'default',
            userSelect: 'none',
          }}>
          {`v${version}`}
        </div>
      </Root>
    </>
  )
}

function Overlay(props) {
  return <div className={classes.overlay} {...props} />
}

function Root(props) {
  return (
    <AppBar position="fixed" color="default" elevation={0} className={classes.AppBar} {...props} />
  )
}

// Substituído por css externo
// const styles = {
//   overlay: {
//     position: 'fixed',
//     backgroundColor: '#4D2140',
//     top: 0,
//     bottom: 0,
//     left: 0,
//     right: 0,
//     zIndex: 1100,
//     opacity: 0.9
//   },
//   AppBar: {
//     top: 'auto',
//     bottom: 0,
//     backgroundColor: '#DBDBDB'
//   },
//   Toolbar: {
//     minHeight: 'auto',
//     paddingTop: 8
//   },
//   hr: {
//     border: 'none',
//     borderTop: '1px solid #C1BABA',
//     width: '95%'
//   }
// }

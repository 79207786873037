export default function fieldHashToArray(hash) {
  return Object.keys(hash).map(key => {
    return {
      field_id: key,
      value: hash[key]?.optionId || hash[key],
      amount: hash[key]?.amount_in_cents ? hash[key].amount_in_cents / 100 || 0 : null,
    }
  })
}
/*

converts:
{36739:2}

to:
[{field_id: "36739", value: 2}]

*/

import * as React from 'react'
import styled from 'styled-components'
import InputLabel from '@material-ui/core/InputLabel'

interface ILabelProps {
  fontSize?: string
  marginBottom?: string
  marginTop?: string
  color?: string
}

const UiLabel = styled(({ fontSize, marginBottom, marginTop, ...props }) => (
  <InputLabel {...props} />
))`
  && {
    color: ${({ color }: ILabelProps) => color || 'rgba(0, 0, 0, 0.90)'};
    font-weight: 500;
    font-size: ${({ fontSize }: ILabelProps) => fontSize || '16px'};
    margin-bottom: ${({ marginBottom }: ILabelProps) => marginBottom || '0px'};
    margin-top: ${({ marginTop }: ILabelProps) => marginTop || '0px'};
  }
`
export default UiLabel

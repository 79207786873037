import React from 'react'
import wretch from 'wretch'

import useStore from '../../stores/useStore'

import UiFeedback from './UiFeedback'

const UiFeedbackSubscription = props => {
  const {
    leaderParticipant: { id: code },
  } = useStore()
  return (
    <UiFeedback
      onClick={async value => {
        await wretch(`https://e-inscricao.firebaseio.com/stats/${code}.json`)
          .patch({
            feedback: value,
          })
          .res(res => console.log(code, res))
      }}
      {...props}
    />
  )
}

export default UiFeedbackSubscription

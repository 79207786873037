import CustomField from './CustomField'
import React from 'react'
import { patch } from '../../../utils'

// data, array of:
// {
//   "id": 590664,
//   "label": "Quais linguagens domina?",
//   "kind": "multi_choice",
//   "required": true,
//   "options": [
//     {
//       "id": 1333002,
//       "label": "Ruby"
//     }
//   ]
// }
// value, map like: {[data.id]: data.value}
// {590664: 1333002}
// errors, map like: {[data.id]: "Error Message"}
// {590664: "Campo Obrigatório"}
export default ({ data = [], value = {}, errors = {}, onChange, ...props }) => {
  return data.map(({ id, kind, ...iProps }) => {
    return (
      <CustomField
        title={`${kind}-${id}`}
        key={id}
        kind={kind}
        {...props}
        {...iProps}
        value={value[id]}
        error={errors[id]}
        onChange={patch(value, id, onChange)}
      />
    )
  })
}

// https://mobile.ant.design/components/stepper/
import React from 'react'
import { IconButton, InputBase } from '@material-ui/core'
import { MinusIcon, PlusIcon } from '../../icons'

const css = {
  ButtonBase: { padding: 0 },
  InputBase: { width: 30 },
  inputProps: {
    style: {
      textAlign: 'center',
      fontWeight: 'bold',
      padding: 0,
    },
  },
  inlineFlex: { display: 'inline-flex' },
}

const ButtonBase = props => (
  <IconButton {...props} style={css.ButtonBase} color="secondary" disableRipple />
)

const ButtonDown = props => (
  <ButtonBase {...props}>
    <MinusIcon />
  </ButtonBase>
)

const ButtonUp = props => (
  <ButtonBase {...props}>
    <PlusIcon />
  </ButtonBase>
)

const InputNumber = props => (
  <InputBase type="tel" readOnly {...props} inputProps={css.inputProps} style={css.InputBase} />
)

export default function StepInputView({ downProps, upProps, ...props }) {
  return (
    <div style={css.inlineFlex}>
      <ButtonDown {...downProps} />
      <InputNumber {...props} />
      <ButtonUp {...upProps} />
    </div>
  )
}

import React from 'react'
import get from 'lodash/get'
import { connect } from 'formik'
import { UiCpfCnpjInput } from '../../ui'
import isDocumentValid from '../../utils/isDocumentValid'

function documentError(documentType, value) {
  if (documentType === 'cpf') {
    return !isDocumentValid(value, 'cpf') && 'cpf inválido'
  }
  if (documentType === 'cnpj') {
    return !isDocumentValid(value, 'cnpj') && 'cnpj inválido'
  }
}

const UiFieldDocumentFormik = ({ name, formik, type, placeholder, documentType, ...props }) => {
  const handleChange = value => {
    formik.setFieldValue(name, value)
  }

  const { values, errors, touched, handleBlur, setErrors } = formik
  const value = get(values, name, '')
  const wasTouched = get(touched, name)
  const fieldError = wasTouched && get(errors, name, null)
  const documentErrorMsg = documentError(documentType, value)
  documentErrorMsg && setErrors({ ...errors, [name]: documentErrorMsg })
  return (
    <UiCpfCnpjInput
      error={fieldError || documentErrorMsg}
      helperText={fieldError || documentErrorMsg}
      value={value}
      onChange={value => handleChange(value)}
      onBlur={handleBlur}
      name={name}
      type={type || 'text'}
      placeholder={placeholder}
      documentType={documentType}
      {...props}
    />
  )
}

export default connect(UiFieldDocumentFormik)

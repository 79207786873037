import React, { useState } from 'react'
import { Spinner } from '../ui'

export default function UiIframe({ src }) {
  const [loading, setloading] = useState(true)
  return (
    <div style={styles.root}>
      <div style={styles.iframe}>{loading && <Spinner style={styles.spinner} />}</div>
      <iframe title={src} src={src} style={styles.iframe} onLoad={() => setloading(false)}></iframe>
    </div>
  )
}

const styles = {
  root: {
    position: 'relative',
    flexGrow: 1,
  },

  iframe: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    border: 'none',
  },

  spinner: {
    position: 'relative',
    marginTop: '50%',
    marginLeft: '50%',
    width: 100,
    height: 100,
    left: -50,
    top: -50,
  },
}

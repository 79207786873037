import { useEffect } from 'react'

interface EventRedirect {
  id: number
  url: string
}

const useRedirect = (event: { id: number }, redirects: EventRedirect[]) => {
  useEffect(() => {
    const redirect = redirects.find(r => r.id === event.id)
    if (redirect) {
      window.location.assign(redirect.url)
    }
  }, [event.id, redirects])
}

export default useRedirect

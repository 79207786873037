import React from 'react'
import ResumeFragment from './ResumeFragment'
import useStore from '../../stores/useStore'
import { view } from 'react-easy-state'

function ResumeContainer({ drawer, handleDrawer }) {
  const { qtys, sum, sumWithFee } = useStore()
  return (
    <ResumeFragment
      qty={qtys}
      total={sumWithFee || sum}
      drawer={drawer}
      handleDrawer={handleDrawer}
    />
  )
}

export default view(ResumeContainer)

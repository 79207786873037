import React from 'react'
import { UiLabel, UiTextField } from '../../../../ui'

export default ({ label, required, ...props }) => {
  return (
    <>
      <UiLabel required={required} fontSize="14px" marginTop="10px">
        {label}
      </UiLabel>
      <UiTextField {...props} />
    </>
  )
}

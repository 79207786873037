import React from 'react'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import global from './global'

const theme = createMuiTheme(global)

const MuiThemeProviderLight = props => (
  <MuiThemeProvider {...props} theme={theme} />
)

export default MuiThemeProviderLight

import * as firebase from 'firebase/app'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyBOzxqZKlnsPCag4jist6QEMqQRryp1-zo',
  authDomain: 'e-inscricao.firebaseapp.com',
  databaseURL: 'https://e-inscricao.firebaseio.com',
  projectId: 'e-inscricao',
  storageBucket: 'e-inscricao.appspot.com',
  messagingSenderId: '322151011187',
  appId: '1:322151011187:web:a5150f30446b0ea5',
}

firebase.initializeApp(firebaseConfig)

export default firebase

export function upload(path, file) {
  return firebase
    .storage()
    .ref()
    .child(path)
    .put(file)
}

import React, { useState } from 'react'
import { UiTextField, UiLink } from '../../ui'
import styled from 'styled-components'
import get from 'lodash/get'
import { connect } from 'formik'

import Mailcheck from 'mailcheck'

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const Suggestion = styled.div`
  display: flex;
  flex-direction: row;
  span {
    margin-right: 5px;
  }
`

const MailCheckInputFormik = ({ name, id, label, onSuggestionChange, formik, ...newProps }) => {
  const [emailSuggestion, setEmailSuggestion] = useState(null)

  const domains = ['gmail.com', 'hotmail.com', 'live.com', 'ig.com.br']
  const secondLevelDomains = ['hotmail', 'gmail', 'live', 'ig']
  const topLevelDomains = ['com', 'net', 'org', 'com.br']

  const handleChange = value => {
    formik.setFieldValue(name, value)
  }

  const { values, errors, touched, handleBlur } = formik
  const value = get(values, name, '')
  const wasTouched = get(touched, name)
  const fieldError = wasTouched && get(errors, name, null)

  return (
    <Content>
      <UiTextField
        error={fieldError}
        helperText={fieldError}
        value={value}
        onBlur={handleBlur}
        name={name}
        id={id}
        label={label || 'Email'}
        onChange={value => {
          handleChange(value)
          Mailcheck.run({
            domains,
            secondLevelDomains,
            topLevelDomains,
            email: value,
            suggested: function(suggestion) {
              setEmailSuggestion(suggestion.full)
              onSuggestionChange && onSuggestionChange(suggestion.full)
            },
            empty: function() {
              setEmailSuggestion(null)
              onSuggestionChange && onSuggestionChange(null)
            },
          })
        }}
        {...newProps}
      />
      {emailSuggestion && (
        <Suggestion>
          <span>Você quis dizer</span>
          <UiLink
            onClick={() => {
              handleChange(emailSuggestion)
              setEmailSuggestion(null)
            }}>
            <i>{emailSuggestion}</i>
          </UiLink>
          <span>?</span>
        </Suggestion>
      )}
    </Content>
  )
}

export default connect(MailCheckInputFormik)

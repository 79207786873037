import React from 'react'

export default React.createContext([
  'cpf',
  'rg',
  'cnpj',
  'cnh',
  'passport',
  'birth_certificate'
])

import React from 'react'
import { IconButton } from '@material-ui/core'
import { SaveIcon } from '../../icons'
import { CheckIcon } from '../../icons'
import UiFileInput from '../UiFileInput'
import Progress from './UiFireUploadProgress'

export default function UiFireUploadView({ isDone, onChange, progress, color = 'secondary' }) {
  const isLoading = typeof progress == 'number' && progress < 100
  return (
    <IconButton color={color} disabled={isLoading}>
      {isDone && !isLoading ? <CheckIcon /> : <SaveIcon />}
      {isLoading && <Progress color={color} progress={progress} />}
      <UiFileInput onChange={onChange} />
    </IconButton>
  )
}

import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import styled from 'styled-components'

const TextFieldStyled = styled(TextField)`
  && {
    [class^="MuiInputBase"] {
      font-size: 16px; !important
    }
  }
`

export default function UiAutoComplete({
  id,
  value,
  defaultValue,
  onChange,
  error = false,
  options,
  getOptionLabel,
  label,
  ...props
}) {
  value = defaultValue !== undefined ? undefined : value || '' // force to be controlled
  return (
    <Autocomplete
      id={id}
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option, value) => value === ''}
      onChange={(event, newValue) => {
        onChange(newValue)
      }}
      value={value}
      renderInput={params => (
        <TextFieldStyled
          type="text"
          fullWidth
          margin="dense"
          error={!!error}
          helperText={error}
          autoComplete="off"
          label={label}
          {...params}
        />
      )}
    />
  )
}

export default function calcLeaderDiscount(groupDiscount, selectedCategories) {
  if (!groupDiscount) return null
  if (!groupDiscount.leader_free) return null
  const qtys = selectedCategories.map(o => o.qty).reduce(sum, 0)
  if (qtys < groupDiscount.min_group_size) return null
  return selectedCategories[0].price
}

function sum(a, b) {
  return Number(a) + Number(b)
}

import React from 'react'
import { MuiThemeProviderDark } from '../../../theme'
import { AppBar } from '@material-ui/core'

export default ({ children, ...props }) => (
  <MuiThemeProviderDark>
    <AppBar position="sticky" elevation={0} {...props}>
      {children}
    </AppBar>
  </MuiThemeProviderDark>
)

import { sessionStorage } from '../safe-storage'

function loadFromSession() {
  try {
    const json = sessionStorage.getItem('userDataParticipantsList')
    if (json) return JSON.parse(json)
  } catch (e) {
    return null
  }
}

function loadFromRails() {
  return window.EINSCRICAO_USER?.participants
}

export function loadUserParticipantsList() {
  return loadFromSession() || loadFromRails()
}

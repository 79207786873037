import { Typography, Box } from '@material-ui/core'
import React, { useState } from 'react'
import { UiButton, UiAutoComplete } from '../../ui'
import useStore from '../../stores/useStore'

export function ParticipantChooser({ onChange = console.warn }) {
  const [autoComplete, setAutoComplete] = useState('')
  const { participantsOptions } = useStore()
  const participantOptionsObject = participantsOptions.map(val => ({ id: val[0], name: val[1] }))
  return (
    <>
      <UiAutoComplete
        id="participantChooser"
        options={participantOptionsObject}
        onChange={value => {
          onChange(value ? value.id : '')
          setAutoComplete(value ? value.name : '')
        }}
        getOptionLabel={option => option?.name || ''}
        value={autoComplete}
        label="Escolher participante cadastrado"
      />
      <Ou />
      <UiButton variant="outlined" onClick={() => onChange(null)}>
        Adicionar novo participante
      </UiButton>
    </>
  )
}

// function Spacer({ height = 1 }) {
//   return <div style={{ height: 8 * height }}></div>
// }

function Ou() {
  return (
    <Box m={1}>
      <Typography style={{ textAlign: 'center', display: 'block', fontWeight: '500' }}>
        ou
      </Typography>
    </Box>
  )
}

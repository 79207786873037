let url = window.location.search

const params = new URLSearchParams(url)
const paramsObject = {}
for (const pair of params.entries()) {
  paramsObject[pair[0]] = pair[1]
}

export const NODE_ENV =
  paramsObject.NODE_ENV || process.env.NODE_ENV === 'production' ? 'test' : 'development'
export const API_URL = paramsObject.apiUrl || process.env.REACT_APP_API_URL
export const VERSION = paramsObject.REACT_APP_VERSION || process.env.REACT_APP_VERSION
export const LOGROCKET_APPID =
  paramsObject.REACT_APP_LOGROCKET_APPID || process.env.REACT_APP_LOGROCKET_APPID
export const URL_RECEIPT = paramsObject.receiptUrl || process.env.REACT_APP_URL_RECEIPT
export const LOG = paramsObject.LOG === 'true' ? true : false
export const LOG_ID = paramsObject.REACT_APP_LOGROCKET_APPID || 'fdbxrl/fluxo'
export const API_PARTICIPANTS_URL = paramsObject.apiParticipantsUrl || 'staging'

export const MS_BASE_URL = paramsObject.msApiUrl || 'https://ei-pay.e-inscricao.com'
export const HOST_URL = paramsObject.hostUrl || ''
export const DEV = NODE_ENV === 'development'
export const PROD = NODE_ENV === 'production'
export const TEST = NODE_ENV === 'test'

export default {
  NODE_ENV,
  DEV,
  PROD,
  TEST,
  API_URL,
  VERSION,
  LOGROCKET_APPID,
  URL_RECEIPT,
  MS_BASE_URL,
  LOG,
  HOST_URL,
}

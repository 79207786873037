import React from 'react'
import { PaymentList } from './PaymentList'
import { view } from 'react-easy-state'
import useStore from '../../stores/useStore'

function PaymentListContainer() {
  const { paymentMethodsWithPrice } = useStore()
  return <PaymentList list={paymentMethodsWithPrice} />
}

export default view(PaymentListContainer)

import React from 'react'
import { UiVbox } from '../../../ui'
import { SigninLink } from '../shared/SigninLink'
import { SignupForm } from './SignupForm'

export function SignupPage() {
  return (
    <UiVbox spacing={3}>
      <SignupForm />
      <SigninLink />
    </UiVbox>
  )
}

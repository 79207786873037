import { sessionStorage } from '../safe-storage'

function getTokenFromSession() {
  try {
    return JSON.parse(sessionStorage.getItem('userData'))?.meta?.token
  } catch (e) {
    return ''
  }
}

function getTokenFromRails() {
  return window.EINSCRICAO_USER?.meta?.token
}

export function getToken() {
  return getTokenFromSession() || getTokenFromRails()
}

export function isLoggedIn() {
  return !!getToken()
}

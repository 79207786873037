import { IconButton, Snackbar } from '@material-ui/core'
import { CheckCircleIcon, CloseIcon, ErrorIcon, InfoIcon, WarningIcon } from '../icons'
import React from 'react'

const UiSnackbar = ({ closeable, variant, onClose, ...props }) => {
  props = addCloseAction(closeable, onClose, props)
  props = addVariant(variant, props)
  return <Snackbar {...props} onClose={onClose} />
}

function addCloseAction(closeable, onClose, props) {
  if (!closeable) return props
  const action = props.action || [
    <IconButton key="close" color="inherit" onClick={e => onClose(e, 'clickbutton')}>
      <CloseIcon />
    </IconButton>,
  ]
  return { ...props, action }
}

const ICONS = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const COLORS = {
  success: '#43a047',
  warning: '#ffa000',
  error: '#d32f2f',
  info: '#1976d2',
}

function addVariant(variant, props) {
  if (!(variant && ICONS[variant])) return props
  const Icon = ICONS[variant]
  const backgroundColor = COLORS[variant] || 'inherit'
  const ContentProps = {
    ...props.ContentProps,
    style: {
      ...(props.ContentProps && props.ContentProps.style),
      backgroundColor,
    },
  }
  const message = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icon style={{ marginRight: 5 }} />
      {props.message}
    </div>
  )
  return { ...props, message, ContentProps }
}

export default UiSnackbar

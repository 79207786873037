import React from 'react'
import { Stepper, Step, withStyles, StepLabel } from '@material-ui/core'

// https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/StepLabel/StepLabel.js#L13
const StepLabelStyled = withStyles(
  {
    label: { '&$alternativeLabel': { marginTop: 5, fontSize: 10 } },
    alternativeLabel: {},
    iconContainer: {
      // backgroundColor: 'white'
      // TODO fazer da maneira correta:
      // https://github.com/cssinjs/jss-nested
      // https://material-ui.com/customization/overrides/#overriding-with-classes
      // https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/StepLabel/StepLabel.js
      // https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/StepIcon/StepIcon.js
      // '& svg[class*=active]': {
      //   color:
      //     theme.palette.type === 'dark' ? 'white' : theme.palette.primary.main
      // },
      // '& svg[class*=active] text': {
      //   fill: theme.palette.type === 'dark' ? theme.palette.primary.main : 'white'
      // }
    }
  },
  { name: 'StepLabelStyled' }
)(StepLabel)

const styles = {
  Stepper: { padding: 8, flexGrow: 1 }
}

// FIXME receber parâmetro de quais steps deve exibir
const FluxoStepper = ({ steps = [], ...props }) => (
  <Stepper
    elevation={0}
    alternativeLabel
    nonLinear
    style={styles.Stepper}
    {...props}
  >
    {steps.map(label => (
      <Step key={label}>
        <StepLabelStyled>{label}</StepLabelStyled>
      </Step>
    ))}
  </Stepper>
)

export default FluxoStepper

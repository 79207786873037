import React from 'react'
import { UiLabel, UiSelect } from '../../../../ui'

export default function ListKind({ options, label, required, ...props }) {
  const optionsArray = options.map(o => [o.id, o.label, disabled(o)])
  return (
    <>
      <UiLabel required={required} fontSize="14px" marginTop="10px">
        {label}
      </UiLabel>
      <UiSelect options={optionsArray} {...props} />
    </>
  )
}

function disabled(o) {
  return o.limit !== null && o.current_in_use >= o.limit
}

import { Typography, Card, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { KeyIcon, PhoneIcon, QrcodeIcon, SuccessIcon, TimerIcon } from '../../icons'
import React from 'react'

const useStyles = makeStyles({
  grid: {
    paddingBottom: '16px',
  },
  card: {
    padding: '16px 16px 8px 0px',
  },
  icon: {
    alignSelf: 'center',
    textAlign: 'center',
  },
})

export default function PixCardDetails() {
  const classes = useStyles()

  const cardDetailsArray = [
    {
      icon: <PhoneIcon />,
      text: 'Abra o app do seu banco ou instituição financeira e entre no ambiente Pix.',
    },
    {
      icon: <QrcodeIcon />,
      text:
        'Escolha a opção pagar com QR code e escaneie o código que irá aparecer na próxima etapa.',
    },
    {
      icon: <KeyIcon />,
      text: 'Ou, se preferir, pague com a Chave Pix que também irá aparecer na próxima etapa.',
    },
    {
      icon: <TimerIcon />,
      text: ' O prazo para pagamento via Pix é de 10 minutos, iniciados na próxima etapa.',
    },
    {
      icon: <SuccessIcon />,
      text: 'A confirmação do pagamento será realizada em poucos minutos.',
    },
  ]

  return (
    <Card className={classes.card}>
      {cardDetailsArray.map(element => (
        <Grid container className={classes.grid} key={`item-${element.text}`}>
          <Grid item xs={2} className={classes.icon}>
            {element.icon}
          </Grid>
          <Grid item xs={10}>
            <Typography variant="body2">{element.text}</Typography>
          </Grid>
        </Grid>
      ))}
    </Card>
  )
}

import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import 'utils/console.json'
import './plugins'
import './$global'

import './index.css'
import App from './App'
// import whyDidYouRender from '@welldone-software/why-did-you-render'

// if (process.env.NODE_ENV !== 'production') {
//   whyDidYouRender(React)
// }

// document.addEventListener('mouseover', function(e) {
//   e.target.classList.add('z-hover')
// })
// document.addEventListener('mouseout', function(e) {
//   e.target.classList.remove('z-hover')
// })

// const App = () => <h1>Blank App</h1>

ReactDOM.render(<App />, document.getElementById('root'))

// https://daveceddia.com/hot-reloading-create-react-app/
if (module.hot) {
  module.hot.accept()
}

// https://medium.com/@brianhan/hot-reloading-cra-without-eject-b54af352c642
// if (module.hot) {
//   module.hot.accept('./App', () => {
//     const NextApp = require('./App').default
//     ReactDOM.render(<NextApp />, rootEl)
//   })
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

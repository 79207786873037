import { useEffect } from 'react'
import useStore from '../stores/useStore'
import { view } from 'react-easy-state'
import { useLocation } from 'wouter'
import Tracker from '../plugins/tracker'

function HomePage() {
  const { loadEvent } = useStore()
  const [, setLocation] = useLocation()

  useEffect(() => {
    loadEvent().then(event => {
      Tracker.init(event.integrations)
      Tracker.pageView()
      setLocation('/tickets')
    })
  }, [loadEvent, setLocation])

  return null
}

export default view(HomePage)

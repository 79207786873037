import React from 'react'
import { Grid } from '@material-ui/core'

const Vbox = props => (
  <Grid container direction="column" alignItems="stretch" spacing={2} {...props}>
    {React.Children.map(props.children, child => (
      <Grid item>{child}</Grid>
    ))}
  </Grid>
)

export default Vbox

import React from 'react'
import useStore from '../../stores/useStore'
import { Typography, FormControlLabel, Radio } from '@material-ui/core'
import { view } from 'react-easy-state'

type TitleProps = {
  id: string
  position?: number
  categoryLabel?: string
  isLeader?: boolean
}

export default view(Title)
function Title({ id, position = 2, categoryLabel = 'Não Informada', isLeader = true }: TitleProps) {
  const { setLeaderPosition, isSameParticipant, canShowSameParticipantToggle } = useStore()

  return (
    <>
      <Typography variant="subtitle2" gutterBottom>
        <span title={id}>Participante</span>
        {!canShowSameParticipantToggle || isSameParticipant
          ? ` - ${categoryLabel}`
          : ` ${position} - ${categoryLabel}`}
      </Typography>
      {!canShowSameParticipantToggle || isSameParticipant ? null : (
        <FormControlLabel
          control={<Radio checked={isLeader} onClick={e => setLeaderPosition(position)} />}
          label="Lider do grupo"
        />
      )}
    </>
  )
}
